<form [formGroup]="createTaskform" (ngSubmit)="onSubmit(createTaskform.value)">
  <p-toast position="top-right" key="tl"></p-toast>
  <div class="p-grid p-fluid">
    <div class="p-col-12 p-md-12">
      <div class="card">
        <div class="p-fluid p-formgrid p-grid">
          <div class=" p-col-12 p-md-2">
            <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left" label="Back"
              (click)=phasetree(editProjectName) class="p-button-raised p-button-primary p-mr-2 p-mb-2"
              style="width:50%"></button>
          </div>
          <div class=" p-col-12 p-md-8" style="text-align:center;">
            <h4 style="margin-top:5px;">{{formType}} Milestone</h4>
          </div>
          <div class=" p-col-12 p-md-2"></div>
        </div>
        <hr>
        <br><br>
        <div>
          <div class="p-grid">
            <div class="p-col-12 p-md-4 p-xl-4">
              <div class="card no-gutter widget-overview-box widget-overview-box-1">
                <ul class="widget-image-list">
                  <li>
                    <span style="font-weight: bold;">Project Name:</span>
                    <span>{{editTaskData?.projectName}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Project Description:</span>
                    <span>{{ editTaskData != undefined ? editTaskData.projectDescription: "" }}</span>
                  </li>

                  <li>
                    <span style="font-weight: bold;">Die Code:</span>
                    <span>{{ editTaskData != undefined ? editTaskData.dieCode : ""}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Mfg Part #:</span>
                    <span>{{ editTaskData != undefined ? editTaskData.mfgPart: "" }}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Package Name:</span>
                    <span>{{ editTaskData?.packageName}}</span>
                  </li>
                </ul>
              </div>
              <div style="margin-top: -12px;">
                <label>Target Start Date</label><button *ngIf="editPlannedStartDateCanEdit == true" class="p-button-raised p-button-primary"
                style="margin-left:15px;width: 120px; height: 25px;" icon="pi" iconPos="left" pButton
                type="button" label="Override" (click)="CMSRedirection()"></button>
                  <!-- <span *ngIf="editPlannedStartDateCanEdit == true" class="cmmessage"><i>:({{changeManagementMessage}})</i></span></label> -->
                <p-calendar [showIcon]="true" [minDate]="plannedStartMinDate" [styleClass]="targetOverride == true ? 'override': ''"
                  [(ngModel)]="editPlannedStartDate" [required]="isDateValidationRequired == true" [disabled]="FCPlannedStartDateD"
                  formControlName="FCPlannedStartDate" requiredMessage="The input must not be empty"></p-calendar> 
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCPlannedStartDate.hasError('required')">
                  Target start date is not selected</small>
              </div>
              <div style="margin-top: 12px;">
                <label>Current Start Date</label><button *ngIf="editRevisedPlannedStartDateCanEdit == true" class="p-button-raised p-button-primary"
                style="margin-left:15px;width: 120px; height: 25px;" icon="pi" iconPos="left" pButton
                type="button" label="Override" (click)="CMSRedirection()"></button>
                  <!-- <span *ngIf="editRevisedPlannedStartDateCanEdit == true" class="cmmessage"><i>:({{changeManagementMessage}})</i></span></label> -->
                <p-calendar [showIcon]="true" [minDate]="editPlannedStartDate" [styleClass]="currentOverride == true ? 'override': ''"
                  [minDate]="plannedStartMinDate" (onSelect)="onSelectRevisedPlannedStartDate($event)"
                  (onClickOutside)="onSelectRevisedPlannedStartDate($event)" [(ngModel)]="editRevisedPlannedStartDate"
                  [required]="isDateValidationRequired == true" formControlName="FCRevisedPlannedStartDate" [disabled]="FCRevisedPlannedStartDateD"
                  requiredMessage="The input must not be empty"></p-calendar>
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCRevisedPlannedStartDate.hasError('required')">
                  Current start date is not selected</small>
              </div>
              <div style="margin-top: 12px;">
                <label>Actual Start Date</label><button *ngIf="editActualStartDateCanEdit == true" class="p-button-raised p-button-primary"
                  style="margin-left:15px;width: 120px; height: 25px;" icon="pi" iconPos="left" pButton
                  type="button" label="Override" (click)="CMSRedirection()"></button>
                  <!-- <span *ngIf="" class="cmmessage"><i>:({{changeManagementMessage}})</i></span> -->
                <!-- <p-calendar [disabledDays]="isIncludeweekends == false ? [0,6] : ''" [showIcon]="true"  [styleClass]="actualStartOverride == true ? 'override': ''"
                  [minDate]="editRevisedPlannedStartDate" [minDate]="plannedStartMinDate"
                  [(ngModel)]="editActualStartDate" (onClickOutside)="StatusUpdate()" (onSelect)="StatusUpdate()"
                  [required]="isActualStartDataRequired == true" formControlName="FCActualStartDate" [disabled]="FCActualStartDateD"
                  requiredMessage="The input must not be empty"></p-calendar> -->
                  <p-calendar [disabledDays]="isIncludeweekends == false ? [0,6] : ''" [showIcon]="true"  [styleClass]="actualStartOverride == true ? 'override': ''"
                  [(ngModel)]="editActualStartDate" (onClickOutside)="StatusUpdate()" (onSelect)="StatusUpdate()"
                  [required]="isActualStartDataRequired == true" formControlName="FCActualStartDate"
                  requiredMessage="The input must not be empty"></p-calendar>
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCActualStartDate.hasError('required')">
                  Actual start date is not selected</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4 p-xl-4">
              <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 137px">
                <ul class="widget-image-list">
                  <li>
                    <span style="font-weight: bold;">Product Engineer:</span>
                    <span>{{ editTaskData != undefined ? editTaskData.projectManagerName : "" }}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Project Start Date:</span>
                    <span>{{editTaskData?.projectStartDate|date:"MM/dd/yyyy"}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Project End Date:</span>
                    <span *ngIf="editTaskData?.targetDate == null || editTaskData?.targetDate == '01/01/1970'">--</span>
                    <span *ngIf="editTaskData?.targetDate != null && editTaskData?.targetDate != '01/01/1970'">
                      {{editTaskData?.projectEndDate|date:"MM/dd/yyyy"}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Project Status:</span>
                    <span>{{ editTaskData != undefined ? editTaskData.projectStatus: ""}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Active Phase:</span>
                    <span>{{ editTaskData != undefined ? editTaskData.activePhase : "" }}</span>
                  </li>
                </ul>
              </div>
              <div style="margin-top: -12px;">
                <label>Target End Date</label><button *ngIf="editPlannedEndDateCanEdit == true" class="p-button-raised p-button-primary"
                style="margin-left:15px;width: 120px; height: 25px;" icon="pi" iconPos="left" pButton
                type="button" label="Override" (click)="CMSRedirection()"></button>
                  <!-- <span *ngIf="editPlannedEndDateCanEdit == true" class="cmmessage"><i>:({{changeManagementMessage}})</i></span></label> -->
                <p-calendar [showIcon]="true" [(ngModel)]="editPlannedEndDate" [styleClass]="targetOverride == true ? 'override': ''"
                  [required]="isDateValidationRequired == true" formControlName="FCPlannedEndDate" [disabled]="FCPlannedEndDateD"
                  [minDate]="editPlannedStartDate" [minDate]="plannedStartMinDate"
                  requiredMessage="The input must not be empty"></p-calendar>
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCPlannedEndDate.hasError('required')">
                  Target end date is not selected</small>
              </div>
              <div style="margin-top: 12px;">
                <label>Current End Date</label><button *ngIf="editRevisedPlannedEndDateCanEdit == true" class="p-button-raised p-button-primary"
                style="margin-left:15px;width: 120px; height: 25px;" icon="pi" iconPos="left" pButton
                type="button" label="Override" (click)="CMSRedirection()"></button>
                  <!-- <span *ngIf="editRevisedPlannedEndDateCanEdit == true" class="cmmessage"><i>:({{changeManagementMessage}})</i></span></label> -->
                <p-calendar [showIcon]="true" [minDate]="editRevisedPlannedStartDate" [styleClass]="currentOverride == true ? 'override': ''"
                  [minDate]="plannedStartMinDate" [(ngModel)]="editRevisedPlannedEndDate"
                  [required]="isDateValidationRequired == true" formControlName="FCRevisedPlannedEndDate" [disabled]="FCRevisedPlannedEndDateD"
                  requiredMessage="The input must not be empty">
                </p-calendar>
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCRevisedPlannedEndDate.hasError('required')">
                  Current end date is not selected</small>
              </div>
              <div style="margin-top: 12px;">
                <label>Actual End Date</label><button *ngIf="editActualEndDateCanEdit == true" class="p-button-raised p-button-primary"
                style="margin-left:15px;width: 120px; height: 25px;" icon="pi" iconPos="left" pButton
                type="button" label="Override" (click)="CMSRedirection()"></button>
                  <!-- <span *ngIf="editActualEndDateCanEdit == true" class="cmmessage"><i>:({{changeManagementMessage}})</i></span></label> -->
                <p-calendar [showIcon]="true" [(ngModel)]="editActualEndDate"  [styleClass]="actualEndOverride == true ? 'override': ''"
                  (onSelect)="StatusUpdate()" (onClickOutside)="StatusUpdate()"
                  [required]="isActualEndDataRequired == true" formControlName="FCActualEndDate" [disabled]="FCActualEndDateD"
                  [minDate]="editActualStartDate" [minDate]="plannedStartMinDate"
                  [disabledDays]="isIncludeweekends == false ? [0,6] : ''" requiredMessage="The input must not be empty">
                </p-calendar>
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCActualEndDate.hasError('required')">
                  Actual end date is not selected</small>
              </div>
            </div>
            <div class="p-col-12 p-md-4 p-xl-4">
              <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 406px">
                <ul class="widget-image-list">
                  <li>
                    <span style="font-weight: bold;">Milestone ID:</span>
                    <span>{{editTaskData?.workTaskId}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Milestone Name:</span>
                    <span>{{editTaskData?.taskName}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Predecessor:</span>
                    <span>{{editTaskData?.pred}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Department:</span>
                    <span>{{editTaskData?.departmentName}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Assignee:</span>
                    <span>{{editTaskData?.assignee}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Milestone Phase:</span>
                    <span>{{editTaskData?.milestonePhase}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Duration:</span>
                    <span>{{editTaskData?.duration}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Include Weekends:</span>
                    <span *ngIf="editTaskData?.isIncludeweekends">Yes</span>
                    <span *ngIf="!editTaskData?.isIncludeweekends">No</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Estimated Effort (Hours):</span>
                    <span>{{editEstimatedEffort}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Man Hours Spent Cumulative:</span>
                    <span>{{editBurntHoursCumulative}}</span>
                  </li>
                  <li>
                    <span style="font-weight: bold;">Notes / Reason:</span>
                    <button pButton type="button" pTooltip="Notes / Reason"
                    tooltipPosition="right" class="p-button-primary" (click)="notesinfo = true"
                    icon="pi pi-eye"
                    style=" height:25px; width:25px; margin-bottom:-10px; margin-top:-10px;"></button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="p-col-12 p-md-3 p-xl-3">
                <label>Milestone Status<span style="color: red">*</span></label>
                <p-dropdown [options]="taskStatus" [(ngModel)]="editSelectedTaskStatus" placeholder="Milestone Status"
                  optionLabel="name" required="true" (onChange)="onTaskStatusChanging($event)" [disabled]="FCTaskStatusD"
                  [disabled]="DisableTaskStatus" formControlName="FCTaskStatus" requiredMessage="The input must not be empty">
                </p-dropdown>
                <small class="p-invalid" *ngIf="submitted && createTaskform.controls.FCTaskStatus.hasError('required')">
                  Milestone status is not selected</small>
            </div>
            <div class="p-col-12 p-md-3 p-xl-3">
                <label>Assign To</label>
                <p-multiSelect [options]="projectUsers" [(ngModel)]="editAssignTo" optionLabel="name"
                  formControlName="FCAssign" (onChange)="onAssignToItemSelect($event)">
                </p-multiSelect>
                <!-- [selectionLimit]="AssignCount"  -->
            </div>
            <div class="p-col-12 p-md-3 p-xl-3">
                <label>Man Hours Spent Today</label>
                <p-inputNumber mode="decimal" [useGrouping]="false" [minFractionDigits]="2"
                  [(ngModel)]="editBurntHoursToday" formControlName="FCBurntHoursToday" [disabled]="FCBurntHoursTodayD" (focusout)="onBurntHourstoday($event)" placeholder="Burnt Hours Today"
                  requiredMessage="The input must not be empty">
                </p-inputNumber>
            </div>
            <div class="p-col-12 p-md-3 p-xl-3">
                <label>Progress Completed</label>
                <input inputId="txtProgress" type="text" formControlName="FCProgress" [disabled]="FCProgressD" [(ngModel)]="editProgressCompleted"
                  pInputText placeholder="Progress Completed" [disabled]="true" />
            </div>
            <div class="p-col-12 p-md-6 p-xl-6">
              <label>Documents</label>
              <hr>
              <div style="height:120px; overflow-y: scroll;">
                <ul *ngFor="let f of file">
                  <li>
                    <span>
                      <span style="width: 150px; margin-left: -35px;" *ngIf="f.isDocAccess==true">
                          <a href="javascript:;" (click)="DMSToken(f)">{{f.displayName | slice:0:40}} </a> 
                      </span>
                      <span style="width: 150px; margin-left: -35px;" *ngIf="f.isDocAccess!=true">{{f.displayName}}</span>

                      <!-- <span style="width: 150px; margin-left: -35px;">
                        <a href="javascript:;" (click)="DMSToken(f.filePath)"> {{f.fileName | slice:0:40}}</a>
                      </span> -->
                      <button *ngIf="IsLoggedIn" class="p-button-danger"
                        style="margin-left: 15px;width: 25px; height: 25px;" icon="pi pi-trash" iconPos="left" pButton
                        type="button" label="" (click)="removeFileFromDB(f)"></button>
                    </span>
                  </li>
                </ul>
              </div>
              <hr>
              <div class="p-formgroup-inline" *ngIf="IsLoggedIn">
                <div class="p-field" style="width: 100%; "><br>
                  <p-fileUpload #fileInput name="file" multiple="multiple" maxFileSize="104857600"
                  accept=".csv,.xls,.xlsx,.xlsm,.doc,.docx,.pdf,.ppt,.pptx,.jpg,.jpeg,.png,.txt,.rar"
                  [showUploadButton]="false" [showCancelButton]="false" fileLimit="5" auto="true">
                  <!-- <ng-template pTemplate="content">
                      <ul *ngIf="fileInput.length">
                          <li *ngFor="let file of fileInput">{{file.name}} - {{file.size}} bytes</li>
                      </ul>
                  </ng-template> -->
                  <ng-template let-file pTemplate='file'>
                    <span>
                      {{file.name | slice:0:40}} <button class="p-button-danger" style="width: 25px; height: 25px;"
                        icon="pi pi-trash" iconPos="left" pButton type="button" label=""
                        (click)="removeFile(file, fileInput)"></button>
                    </span>
                  </ng-template>
                  </p-fileUpload>
                </div>
              </div>
            </div>
            <div class="p-col-12 p-md-6 p-xl-6">
              <div class="p-field" style="width: 100%;">
              <div class="p-col-12 p-md-12">
                <label>Add Notes</label>
                <textarea [(ngModel)]="editNotes" formControlName="FCNotes" [disabled]="FCNotesD" type="text" rows="7" pInputTextarea [disabled]="!IsLoggedIn"></textarea>
              </div>
              <div class="p-col-12 p-md-12">
                <span  style="float: right;">
                <button pButton type="button" label="View Affected Milestone" class="p-button-primary" icon="pi pi-eye" style="width: 250px; margin-right: 30px;"
                  iconPos="left" (click)="GetEffectedTasks()" [disabled]="DisableTaskStatus"></button>
                  <button pButton type="button" label="View History" class="p-button-primary" icon="pi pi-eye" style="width: 180px;"
                  iconPos="left" (click)="getTaskHistory()"></button>
                </span>
              </div>
              <div class="p-col-12 p-md-12"><br><hr></div>
              <div class="p-col-12 p-md-12" style="vertical-align: center">
                <small class="p-invalid" style="float:right;">{{responseError}}</small>
              </div>
              <div class="p-col-12 p-md-12" *ngIf="IsLoggedIn">
                <span  style="float: right;">
                  <button pButton type="button" label="Reset" class="p-button-secondary" icon="pi pi-refresh" iconPos="left" style="width: 150px; margin-right: 30px;"
                  (click)="resetForm()" *ngIf="isresetShown"></button>
                  <button pButton type="submit" label="Save" class="p-button-success" icon="pi pi-plus" style="width: 150px;"
                  iconPos="left"></button>
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 

  <p-dialog header="Effected Milestone" [(visible)]="effectedTasksdisplay" modal="modal" showEffect="fade"
    [style]="{ width: '80%' }">
    <hr />
    <div class="card">
      <div>
        <div style="min-height:200px;margin-top: -14px;border: 2px solid #e9ecef;">
          <p-table [value]="effectedTasksData" [expandedRowKeys]="expandedRows" dataKey="taskId" rowExpandMode="single"
            styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:60px;"></th>
                <th style="width:260px;">Name</th>
                <th style="width:60px;">Seq</th>
                <th>Predecessor</th>
                <th>Duration</th>
                <th>Assignee</th>
                <th style="width:150px;">Status</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-effectedTask let-expanded="expanded">
              <tr style="background-color:#eee;border-bottom: 3px solid #fff;">
                <td class="width50">
                  <button type="button" pButton pRipple [pRowToggler]="effectedTask"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td style="padding-left: 30px;">{{effectedTask.taskName}} </td>
                <td style="text-align:center">{{effectedTask.taskSeq}} </td>
                <td style="padding-left: 30px;">{{effectedTask.predecessor}} </td>
                <td style="padding-left: 30px;">{{effectedTask.duration}} </td>
                <td style="padding-left: 30px;">{{effectedTask.assigneeTo}} </td>
                <td style="text-align:center">{{effectedTask.taskStatus }} </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7">No milestone effected.</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-effectedTask>
              <tr style="border: 2px solid #e9ecef;">
                <td colspan="3">
                  <div style="margin-left:50%;">
                    <p-table [value]="effectedTask.dates" dataKey="name" styleClass="p-datatable-striped">
                      <ng-template pTemplate="header">
              <tr>
                <th style="width:260px;">Name</th>
                <th style="width:260px;">Current</th>
                <th style="width:260px;">Proposed</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-date>
              <tr>
                <td style="padding-left: 15px;">{{date.name}}</td>
                <td style="text-align: center;">{{date.current| date: "MM/dd/yyyy"}}</td>
                <td style="text-align: center;">{{date.proposed| date: "MM/dd/yyyy"}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3">No Data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
      </div>
    </div>
    </div>
  </p-dialog>
  <p-dialog header="Milestone History" [(visible)]="historydisplay" modal="modal" showEffect="fade" [style]="{ width: '90%' }">
    <hr />
              <div class="p-grid">
                  <div class="p-col-12 p-md-12 p-xl-12">

                    <div class="card-header" style="background-color: #f8f9fa; border-radius: 5px">
                      <h5 style="color: #495057; padding: 15px 0 0 15px">
                        {{editTaskName}}
                      </h5>

                      <span style="float: right; margin-top: 5px">

                        <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                          tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                          style=" margin-left:1px;height:25px; width:25px; " (click)="exportExcel('history','excel-table',editTaskName + ' History')"></button>
                        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                          class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float: right"
                          (click)="print('invoice-content',editTaskName + ' History',false)" style="height:25px; width:25px; "></button>
                      </span>
                    </div>
                    <div class="invoice invoice-header" id="invoice-content">
                    <p-table #dt [value]="taskHistory"  id="excel-table" dataKey="id" styleClass="p-datatable-customers p-datatable-striped" [rowHover]="true"
                      [rows]="10" [paginator]="false" [filterDelay]="0">
                      <ng-template pTemplate="header">
                        <tr>
                          <th pSortableColumn="taskStatus" style="text-align:center;width:120px;">
                            Milestone Status
                            <p-sortIcon field="taskStatus"></p-sortIcon>
                          </th>
                          <th pSortableColumn="planedStartDate" style="text-align:center;width:100px;">
                            Target Start Date
                            <p-sortIcon field="planedStartDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="revisedPlannedStartDate" style="text-align:center;width:120px;">
                            Current Start Date
                            <p-sortIcon field="revisedPlannedStartDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="actualStartDate" style="text-align:center;width:100px;">
                            Actual Start Date
                            <p-sortIcon field="actualStartDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="plannedEndDate" style="text-align:center;width:100px;">
                            Target End Date
                            <p-sortIcon field="plannedEndDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="revisedPlannedEndDate" style="text-align:center;width:120px;">
                            Current End Date
                            <p-sortIcon field="revisedPlannedEndDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="actualEndDate" style="text-align:center;width:100px;">
                            Actual End Date
                            <p-sortIcon field="actualEndDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="assignName" style="text-align:center;width:120px;">
                            Assignee
                            <p-sortIcon field="assignName"></p-sortIcon>
                          </th>
                          <th pSortableColumn="burntHoursToday" style="text-align:center;width:120px;">
                            Man Hours
                            <p-sortIcon field="burntHoursToday"></p-sortIcon>
                          </th>
                          <th pSortableColumn="progressCompleted" style="text-align:center;width:120px;">
                            Progress Completed
                            <p-sortIcon field="progressCompleted"></p-sortIcon>
                          </th>
                          <th pSortableColumn="modifiedBy" style="text-align:center;width:120px;">
                            Modified By
                            <p-sortIcon field="modifiedBy"></p-sortIcon>
                          </th>
                          <th pSortableColumn="modifiedDate" style="text-align:center;width:140px;">
                            Modified Date
                            <p-sortIcon field="modifiedDate"></p-sortIcon>
                          </th>
                          <th pSortableColumn="notes" style="text-align:center;width:150px;">
                            Notes
                            <p-sortIcon field="notes"></p-sortIcon>
                          </th>
                          <th style="width:100px;">Documents</th>
                        </tr>

                      </ng-template>
                      <ng-template pTemplate="body" let-task>
                        <tr class="p-selectable-row">
                          <td style="padding-left:15px;"><span>{{ task.taskStatus }}</span></td>
                          <td style="text-align:center;">
                            <span *ngIf="task.planedStartDate == null || task.planedStartDate == '01/01/1970'">--</span>
                            <span *ngIf="task.planedStartDate != null && task.planedStartDate != '01/01/1970'">{{ task.planedStartDate| date: "MM/dd/yyyy" }}</span>
                          </td>
                          <td style="text-align:center;">
                            <span *ngIf="task.revisedPlannedStartDate == null || task.revisedPlannedStartDate == '01/01/1970'">--</span>
                            <span *ngIf="task.revisedPlannedStartDate != null && task.revisedPlannedStartDate != '01/01/1970'">{{ task.revisedPlannedStartDate| date: "MM/dd/yyyy" }}</span>
                          </td>
                          <td style="text-align:center;">
                            <span *ngIf="task.actualStartDate == null || task.actualStartDate == '01/01/1970'">--</span>
                            <span *ngIf="task.actualStartDate != null && task.actualStartDate != '01/01/1970'">{{ task.actualStartDate| date: "MM/dd/yyyy" }}</span>
                          </td>
                          <td style="text-align:center;">
                            <span *ngIf="task.plannedEndDate == null || task.plannedEndDate == '01/01/1970'">--</span>
                            <span *ngIf="task.plannedEndDate != null && task.plannedEndDate != '01/01/1970'">{{ task.plannedEndDate| date: "MM/dd/yyyy" }}</span>
                          </td>
                          <td style="text-align:center;">
                            <span *ngIf="task.revisedPlannedEndDate == null || task.revisedPlannedEndDate == '01/01/1970'">--</span>
                            <span *ngIf="task.revisedPlannedEndDate != null && task.revisedPlannedEndDate != '01/01/1970'">{{ task.revisedPlannedEndDate| date: "MM/dd/yyyy" }}</span>
                          </td>
                          <td style="text-align:center;">
                            <span *ngIf="task.actualEndDate == null || task.actualEndDate == '01/01/1970'">--</span>
                            <span *ngIf="task.actualEndDate != null && task.actualEndDate != '01/01/1970'">{{ task.actualEndDate| date: "MM/dd/yyyy" }}</span>
                          </td>
                          <td style="padding-left:15px;"><span>{{ task.assignName }}</span></td>
                          <td style="text-align:center;"><span>{{ task.burntHoursToday }}</span></td>
                          <td style="text-align:center;"><span>{{ task.progressCompleted }}</span></td>
                          <td style="padding-left:15px;"><span>{{ task.modifiedBy }}</span></td>
                          <td style="text-align:center;"><span>{{ task.modifiedDate | date: "MM/dd/yyyy" }}</span></td>
                          <td style="padding-left:15px;"><span>{{ task.notes }}</span></td>
                          <td style="padding-left:15px;">
                            <ul *ngFor="let f of task.taskFiles">
                              <li>
                                <span>
                                  <span style="width: 150px; margin-left: -35px;">
                                    <a href="javascript:;" (click)="DMSToken(f.documentGuid+'.'+f.fileFormat)">
                                      {{f.fileName | slice:0:40}}
                                    </a>
                                  </span>
                                </span>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="14">No milestone are available to display</td>
                        </tr>
                      </ng-template>
                    </p-table>
</div>
                  </div>
                </div>
  </p-dialog>

</form>


<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>


<p-dialog header="Notes / Reason" [(visible)]="notesinfo" modal="modal" showEffect="fade" [style]="{ 'width': '80%' }">
  <hr />

  <div style="padding: 20px;" *ngIf="allnotes != null && allnotes != undefined">
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
        <p-table [value]="allnotes"  styleClass="p-datatable-striped" [autoLayout]="true" [filterDelay]="0" selectionMode="multiple">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:120px;">Added Date</th>
            <th style="width:150px;">Added By</th>
            <th style="width:300px;">Note / Reason</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td style="width:120px; text-align: center;">{{rowData.addedDate}} </td>
            <td style="text-align:left;padding-left: 15px;width: 150px;">{{rowData.addedBy}} </td>
            <td style="text-align:left;padding-left: 15px;width: 300px;">{{rowData.note}} </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">No notes available.</td>
          </tr>
        </ng-template>
    </p-table>
      </div>
    </div>
  </div>
</p-dialog>
